import React from "react";
import Testimonials from "./Testimonials";
import OurServices from "./OurServices";
import { makeStyles } from "@material-ui/core/styles";
import InquiryForm from "components/Form";
import Glimpse from "./Glimpse";
import AboutUs from "./AboutUs";
import Brands from "./Brands";
import HomeContent from "./HomeContent";
import Advantages from "./Advantages";
// import whatsAppImg from "assets/images/whatsApp.png";

const useStyles = makeStyles({
 cookieAlert: {
  "& .MuiAlert-message": {
   color: "#ffffff",
   fontFamily: "Nunito",
   fontSize: "14px",
  },
 },
 displayInline: {
  display: "inline-block",
 },
});

function LandingPage() {
 const classes = useStyles();

 return (
  <div>
   <div className="preloader">
    <div className="loader">
     <div className="ytp-spinner">
      <div className="ytp-spinner-container">
       <div className="ytp-spinner-rotator">
        <div className="ytp-spinner-left">
         <div className="ytp-spinner-circle"></div>
        </div>
        <div className="ytp-spinner-right">
         <div className="ytp-spinner-circle"></div>
        </div>
       </div>
      </div>
     </div>
    </div>
   </div>

   <header className="header-area ">
    <div className="navbar-area headroom">
     <div className="container">
      <div className="row">
       <div className="col-lg-12">
        <nav className="navbar navbar-expand-lg">
         <a className="navbar-brand" href="index.html">
          <img
           src="assets/images/skyld_logo.png"
           alt="Logo"
           style={{ width: "150px" }}
          />
         </a>
         <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
         >
          <span className="toggler-icon"></span>
          <span className="toggler-icon"></span>
          <span className="toggler-icon"></span>
         </button>

         <div
          className="collapse navbar-collapse sub-menu-bar"
          id="navbarSupportedContent"
         >
          <ul id="nav" className="navbar-nav">
           <li className="contact-number">
            +91 99024-<span className="skyld">SKYLD</span>
           </li>
           <li className="nav-item active">
            <a href="index.html/#home">Home</a>
           </li>

           <li className="nav-item">
            <a href="index.html/#about">About Us </a>
           </li>
           <li className="nav-item">
            <a href="index.html/#services">Services</a>
           </li>

           <li className="nav-item">
            <a href="index.html/#advantage">Advantage</a>
           </li>
          </ul>
         </div>
        </nav>
       </div>
      </div>
     </div>
    </div>
   </header>
   <div className="quick-links-container">
    <div className="items-container">
     <a
      href="https://wa.me/919902475953"
      target="_blank"
      rel="noreferrer"
      style={{ display: "block" }}
     >
      <img src="assets/images/whatsApp.png" alt="whatsapp" />
     </a>
     <div className="overlay">
      <span className="overlay-text">Connect on WhatsApp</span>
     </div>
    </div>
   </div>

   <section>
    <InquiryForm />
   </section>

   <section id="home" className="pt-115">
    <HomeContent classes={classes} />
   </section>

   <section id="glimpse">
    <Glimpse />
   </section>

   <section id="brands">
    <Brands />
   </section>

   <section id="about" className="pt-115">
    <AboutUs />
   </section>

   <section id="services" className="container pt-115">
    <OurServices />
   </section>

   <section id="advantage" className="container pt-115">
    <Advantages />
   </section>

   <section id="testimonials" className="mt-115">
    <Testimonials />
   </section>

   <footer
    id="footer"
    className="footer-area bg_cover mt-100 footer_container"
    style={{ width: "100%" }}
   >
    <div className="container">
     <div className="footer-widget pt-30 pb-40">
      <div className="col-lg-3 col-sm-6 order-sm-1 order-lg-1">
       <div className="footer-about pt-40">
        <a href="/#home">
         <img
          src="assets/images/logo3.png"
          alt="Logo"
          style={{ width: "150px" }}
         />
        </a>
       </div>
      </div>
      <div className="grid-container container">
       <div className="footer_about_us" id="footer-about-us">
        <div className="footer-link pt-40">
         <div className="footer-title">
          <h5 className="title">About Us</h5>
         </div>
         <ul>
          <li className="nav-item">
           <a href="#contact-us">Contact Us</a>
          </li>
          <li>
           <a href="#about"> About Us</a>
          </li>
          <li>
           <a href="#services">Services</a>
          </li>
         </ul>
        </div>
       </div>
       <div className="footer_help" style={{ display: "none" }}>
        <div className="footer-link pt-40">
         <div className="footer-title">
          <h5 className="title">Help</h5>
         </div>
         <ul>
          <li>
           <a href="/#home">Policy</a>
          </li>
          <li>
           <a href="/#home">Terms of Use</a>
          </li>
          <li>
           <a href="/#home">FAQ</a>
          </li>
         </ul>
        </div>
       </div>
       <div className="footer_social" style={{ display: "none" }}>
        <div className="footer-link pt-40">
         <div className="footer-title">
          <h5 className="title">Social</h5>
         </div>
         <ul>
          <li>
           <a href="/#home">Facebook</a>
          </li>
          <li>
           <a href="/#home">Instagram</a>
          </li>
          <li>
           <a href="/#home">Twitter</a>
          </li>
         </ul>
        </div>
       </div>
       <div className="footer_address">
        <div className="footer-link pt-40">
         <div className="footer-title">
          <h5 className="title">Address</h5>
         </div>
         <div className="contact pt-10">
          <p className="text">
           #14, 4th Floor, AJR Pride, 27th Main, 1st Sector, HSR Layout,
           Bangalore -560102 Karnataka, India
          </p>
          <p className="text">hello@skyld.in</p>
          <p className="text">+91 9902475953</p>
          <p className="text">+91 9591475953</p>
         </div>
        </div>
       </div>
      </div>
     </div>
     <div className="social_icons">
      <a href="#facebook">
       <i className="lni lni-facebook-filled"></i>
      </a>
      <a href="#twitter">
       <i className="lni lni-twitter-filled"></i>
      </a>
      <a
       href="https://www.instagram.com/skyld.in/"
       target="_blank"
       rel="noopener noreferrer"
      >
       <i className="lni lni-instagram-filled"></i>
      </a>
      <a href="#linkedin">
       <i className="lni lni-linkedin-original"></i>
      </a>
     </div>
     <div className="footer-copyright text-center">
      <p className="text">
       © 2022 Crafted by{" "}
       <a href="https://uideck.com" rel="nofollow">
        UIdeck
       </a>{" "}
       All Rights Reserved.
      </p>
     </div>
    </div>
   </footer>

   <a href="#home" className="back-to-top">
    <i className="fa-solid fa-arrow-up"></i>
   </a>
  </div>
 );
}

export default LandingPage;

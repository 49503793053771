import { trackPromise } from "react-promise-tracker";
import axios from "axios";

export const callOpenAPI = (
  method,
  path,
  details,
  successCallback,
  errorCallback
) => {
  trackPromise(
    axios({
      method: method,
      url: path,
      headers: {
        "Content-Type": "application/json",
      },
      data: details,
    })
      .then((responseData) => {
        successCallback(responseData);
      })
      .catch((err) => {
        errorCallback(err);
      })
  );
};

import React, { useState, useEffect } from "react";
import TextTransition, { presets } from "react-text-transition";

function HomeContent({ classes }) {
 const TEXTS = ["Skilled", "Skyld"];
 const [index, setIndex] = useState(0);

 useEffect(() => {
  const intervalId = setInterval(
   () => setIndex((index) => index + 1),
   3000 // every 3 seconds
  );
  return () => clearTimeout(intervalId);
 }, []);
 return (
  <div
   id="home"
   className="bg-cover d-lg-flex align-items-center header_padding"
  >
   <div className="container home-content">
    <div className="left-side">
     <div className="header-hero-content">
      <div>
       {/* <h1>
        <span>always be</span>
        <div className="message">
         <div className="word1">Skilled</div>
         <div className="word2">Skyld</div>
        </div>
       </h1> */}

       <h1 className="hero-title wow fadeInUp">
        We are&nbsp;
        <div className={classes.displayInline}>
         <TextTransition
          text={TEXTS[index % TEXTS.length]}
          springConfig={presets.molasses}
         />
        </div>
       </h1>
      </div>
      <div>
       <p
        className="text wow fadeInUp"
        data-wow-duration="1s"
        data-wow-delay="0.5s"
       >
        An all-inclusive technology & skill based platform with a one-stop
        solution for hassle-free maintenance of properties from a host of
        on-demand-services to complete property management.
       </p>
      </div>
     </div>
    </div>
    <div className="right-side">
     <img src="assets/images/hero.png" alt="Hero" />
    </div>
   </div>
  </div>
 );
}

export default HomeContent;

import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  textFieldLabel: {
    // this will be applied when input focused (label color change)
    "&$textFieldLabelFocused": {},
  },
  menuPaper: {
    maxHeight: 200,
    backgroundColor: "#fffff",
  },

  root: {
    fontFamily: "'Nunito', sans-serif",
    color: "#000000",
    fontSize: "17px",
  },

  rootInputAdornment: {
    fontFamily: "'Nunito', sans-serif",
    color: "#000000",
  },

  outlinedInput: {
    "&:hover .MuiInputAdornment-root": {
      color: "green",
    },
    "&.Mui-focused .MuiInputAdornment-root": {
      color: theme.palette.primary.main,
    },
  },

  textFieldLabelFocused: {},
  textFieldRoot: {
    marginRight: theme.spacing(10),
    marginLeft: "20px",
    borderRadius: "8px",
    width: "340px",
    [theme.breakpoints.down("md")]: {
      marginLeft: "0px",
      width: "100%",
    },

    // this will be applied when hovered (input text color change)
    // "&:hover": {
    //   color: "#d5d5d5",
    // },
    // this will applied when hovered (input border color change)
    "&:hover $textFieldNotchedOutline": {
      borderColor: "#d5d5d5",
    },
    // this will be applied when focused (input border color change)
    "&$textFieldFocused $textFieldNotchedOutline": {
      borderColor: "#3c35dd",
    },
    "&$textFieldFocused": {
      color: "#3c35dd",
    },

    "&$textFieldNotchedOutline": {
      borderRadius: "50px",
    },
  },
  textFieldFocused: {},
  textFieldNotchedOutline: {
    borderColor: "#d5d5d5",
  },
  input: {
    "&::placeholder": {
      color: "#000000",
      opacity: 1,
    },
    height: "30px",
  },
}));

export const helperTextStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: "12px",
    color: "red",
    [theme.breakpoints.down("md")]: {
      margin: "0px",
      padding: "0px",
    },
  },
  error: {
    "&.MuiFormHelperText-root.Mui-error": {
      color: "red",
    },
  },
}));

import React, { useState } from "react";
import JSON from "constants/db.json";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import IconButton from "@material-ui/core/IconButton";
import withWidth from "@material-ui/core/withWidth";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";

function OurServices(props) {
 const { width } = props;
 const { ourServices } = JSON;
 const [service, setService] = useState(
  width === "xs" || width === "sm" ? "" : ourServices[0]
 );

 const onClick = (item) => {
  if (service.name === item.name && (width === "xs" || width === "sm")) {
   setService("");
  } else if (item) {
   setService(item);
  }
 };

 return (
  <div className="service-container">
   <h1 className="title-title">Our Services</h1>
   <div className="container">
    <h6 className="title-subtitle">
     100+ vendors, 2000+ qualified skilled professionals across 30+ property
     management related categories
    </h6>
   </div>
   <div className="services_container">
    <div className="left_side">
     {ourServices.map((item, index) => {
      return (
       <>
        <div
         className="item"
         active={service.name === item.name ? "true" : "false"}
         onClick={() => onClick(item)}
         key={item.name}
        >
         <img src={item.image} alt="...." />
         <p className="item_text">{item.name}</p>
         {width === "xs" || width === "sm" ? (
          <IconButton
           style={{
            opacity: service.name === item.name ? 1 : 0.4,
            marginRight: "20px",
            color: "#000000",
           }}
          >
           {service.name === item.name ? (
            <KeyboardArrowDown fontSize="large" />
           ) : (
            <KeyboardArrowUpIcon fontSize="large" />
           )}
          </IconButton>
         ) : (
          <IconButton
           style={{
            opacity: service.name === item.name ? 1 : 0.4,
            marginRight: "20px",
            color: "#000000",
           }}
          >
           <ArrowForwardIosIcon />
          </IconButton>
         )}
        </div>
        {width === "xs" || width === "sm" ? (
         <div className="service_description_mobile">
          {service.name === item.name ? (
           <>
            <p>{item.description}</p>
            <div className="image">
             <img src={service.descriptionImage} alt="....." />
            </div>
           </>
          ) : null}
         </div>
        ) : null}
       </>
      );
     })}
    </div>
    {width === "xs" || width === "sm" ? null : (
     <div className="right_side">
      <div className="service_description">
       <p>{service.description}</p>
       {/* <span>View More</span> */}
       <div className="image">
        <img src={service.descriptionImage} alt="....." />
       </div>
      </div>
     </div>
    )}
   </div>
  </div>
 );
}

export default withWidth()(OurServices);

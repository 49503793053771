import React from "react";
import Slider from "react-slick";
import withWidth from "@material-ui/core/withWidth";
import JSON from "../constants/db.json";

function Glimpse() {
 const { glimpse_videos, glimpse_images } = JSON;
 const settings = {
  infinite: true,
  vertical: false,
  slidesToShow: 3,
  slidesToScroll: 1,
  swipe: false,
  speed: 500,
  autoplaySpeed: 3000,
  autoplay: true,
  arrows: true,
  dots: false,
  useCSS: true,
  cssEase: "linear",
  useTransform: true,
  adaptiveHeight: false,
  centerMode: false,
  rows: 1,
  slidesPerRow: 1,
  responsive: [
   {
    breakpoint: 1024,
    settings: {
     slidesToShow: 3,
     slidesToScroll: 1,
     infinite: true,
     swipe: false,
    },
   },
   {
    breakpoint: 800,
    settings: {
     arrows: true,
     slidesToShow: 1,
     slidesToScroll: 1,
     swipe: false,
    },
   },
   {
    breakpoint: 480,
    settings: {
     arrows: true,
     slidesToShow: 1,
     slidesToScroll: 1,
     swipe: false,
     vertical: false,
     autoplay: true,
    },
   },
  ],
 };

 return (
  <div className="container glimpse pt-115">
   <h1 className="title-title">A Glimpse Of Skyld Expertise</h1>
   <Slider {...settings}>
    {glimpse_videos.map((item) => {
     return (
      <div className="header" key={item.id}>
       <video controls src={item.src} className="header-video" />
      </div>
     );
    })}
    {glimpse_images.map((item) => {
     return (
      <div className="header" key={item.id}>
       <img src={item.src} alt={item.name} className="header-video" />
      </div>
     );
    })}
   </Slider>
  </div>
 );
}

export default withWidth()(Glimpse);

import React from "react";
import Slider from "react-slick";
import JSON from "../constants/db.json";
import { Rating } from "@material-ui/lab";

import withWidth from "@material-ui/core/withWidth";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";

function Testimonials(props) {
 const { width } = props;
 const { ratings } = JSON;
 const slider = React.useRef(null);

 const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
   <KeyboardArrowRightIcon
    fontSize="large"
    className={className}
    onClick={onClick}
    style={{
     ...style,
     display: "block",
     color: "#1C15C3",
     marginRight: width === "xs" || width === "sm" ? "20px" : "-20px",
     zIndex: 1,
     backgroundColor: "#FFFFFF",
     borderRadius: "50%",
     border: "1px solid #a2b2c629",
    }}
   />
  );
 };

 const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
   <KeyboardArrowLeftIcon
    fontSize="large"
    onClick={onClick}
    className={className}
    style={{
     ...style,
     display: "block",
     color: "#1C15C3",
     marginLeft: width === "xs" || width === "sm" ? "20px" : "-20px",
     zIndex: 1,
     backgroundColor: "#FFFFFF",
     borderRadius: "50%",
     border: "1px solid #a2b2c629",
    }}
   />
  );
 };

 const settings = {
  customPaging: function () {
   return (
    <div className="ft-slick__dots--custom">
     <div className="loading" />
    </div>
   );
  },

  infinite: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  swipe: false,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  speed: 500,
  autoplaySpeed: 3000,
  autoplay: true,
  arrows: true,
  dots: true,
  useCSS: true,
  cssEase: "linear",
  useTransform: true,

  responsive: [
   {
    breakpoint: 1024,
    settings: {
     slidesToShow: 3,
     slidesToScroll: 1,
     infinite: true,
     dots: true,
     swipe: false,
    },
   },
   {
    breakpoint: 800,
    settings: {
     arrows: true,
     slidesToShow: 1,
     slidesToScroll: 1,
     swipe: false,
     dots: false,
    },
   },
   {
    breakpoint: 480,
    settings: {
     arrows: true,
     slidesToShow: 1,
     slidesToScroll: 1,
     swipe: false,
     vertical: false,
     autoplay: true,
     dots: false,
    },
   },
  ],
 };
 return (
  <div className="container testimonial_container">
   <h1 className="title-title">Testimonials</h1>
   <p className="title-subtitle">Here’s what our patrons say…</p>
   <Slider ref={slider} {...settings}>
    {ratings.map((item, index) => {
     return (
      <div className="testimonial_container_main" key={index}>
       <div className="profile">
        <div className="imgBox">
         <img src={item.image} alt="...." />
        </div>
       </div>
       <div className="rating">
        <Rating name="read-only" value={item.rating} precision={0.5} readOnly />
       </div>
       <p>{item.comment}</p>
       <div className="details">
        <div className="name">
         <p>{item.name}</p>
        </div>
        <div className="location">
         <p>{`${item.designation}${item.location}`}</p>
        </div>
       </div>
      </div>
     );
    })}
   </Slider>
  </div>
 );
}

export default withWidth()(Testimonials);

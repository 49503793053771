import React from "react";

function AboutUs() {
 return (
  <div className="about_section">
   <div className="about_container container">
    <div className="about_title">
     <h1 className="title-title">About Us</h1>
    </div>

    <div className="about-leftside">
     <div className="about-leftside_container">
      <img src={"/assets/images/about/about_us.png"} alt="...." />
     </div>
    </div>
    <div className="about-rightside">
     <h1>About Us</h1>
     <p>
      Skyld is an all-inclusive technology & skill based platform with a one
      stop solution for hassle-free maintenance of properties ranging from
      on-demand services to total property management.
     </p>
     <p>
      With 100+ verified vendors, 400+ qualified skilled professionals in tier 1
      cities & a customer satisfaction score of 4.6 of 5, our mission is to
      create a simplified property management platform for our customers &
      workforce enabling a seamless execution of property related service and a
      world class experience
     </p>
    </div>
   </div>
  </div>
 );
}

export default AboutUs;

import React from "react";
import JSON from "constants/db.json";

function Brands() {
 const { brands } = JSON;
 return (
  <div className="container brand-container pt-115">
   <h1 className="title-title">Brands That Trust Us</h1>
   <div className="mt-40 brand_image">
    {brands.map((image) => {
     return (
      <img
       className="img"
       src={image.image}
       alt={`img-${image.id}`}
       key={image.id}
      />
     );
    })}
   </div>
  </div>
 );
}

export default Brands;

import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Button from "./Button";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import withWidth from "@material-ui/core/withWidth";
import { callOpenAPI } from "utils/httpUtil";
import { createLead } from "utils/apiEndPoints";
import { useStyles } from "assets/jss/form/formStyle.js";
import JSON from "constants/db.json";

function Alert(AlertProps) {
 return <MuiAlert elevation={6} variant="filled" {...AlertProps} />;
}

const InquiryForm = (props) => {
 const { width } = props;
 //  console.log(width);
 const classes = useStyles();
 const [isOpen, setIsOpen] = useState(
  width === "xs" || width === "sm" ? false : true
 );
 const [alert, setAlert] = useState({
  error: false,
  message: null,
  color: null,
 });
 const vertical = "bottom";
 const horizontal = "center";
 const defaultValues = {
  phoneNumber: "",
  email: "",
  service: "null",
  comments: "",
 };

 const {
  register,
  handleSubmit,
  formState: { errors },
  reset,
 } = useForm({ defaultValues });

 const { ourServices } = JSON;
 const toggleForm = () => {
  setIsOpen(!isOpen);
 };

 const apiCallBackOnSuccess = (res) => {
  if (res.data.message === "SUCCESS") {
   setAlert({
    error: true,
    message:
     " Thank-You for showing Interest in Skyld, Our team will reach out to you soon",
    color: "success",
   });
   reset({
    phoneNumber: "",
    email: "",
    service: "null",
    comments: "",
   });
  }
 };
 const apiErrorCallBack = (err) => {
  console.log(err.response.data.message);
  setAlert({
   error: true,
   message:
    err.response.data.message !== ""
     ? err.response.data.message
     : "Internal error please try after sometime",
   color: "error",
  });
 };

 const onSubmit = async (data) => {
  await callOpenAPI(
   "POST",
   createLead,
   {
    email: data.email,
    phoneNo: data.phoneNumber,
    reasonForContacting: data.comments === "" ? null : data.comments,
    serviceTye: data.service === "null" ? null : data.service,
   },
   apiCallBackOnSuccess,
   apiErrorCallBack
  );
 };
 return (
  <>
   {alert.error ? (
    <div>
     <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      open={alert.error}
      autoHideDuration={6000}
      onClose={() =>
       setAlert({
        error: false,
       })
      }
     >
      <Alert
       className={classes.cookieAlert}
       onClose={() =>
        setAlert({
         error: false,
        })
       }
       severity={alert.color}
      >
       {alert.message}
      </Alert>
     </Snackbar>
    </div>
   ) : null}

   <div className="contact-form-container">
    <Button
     type="submit"
     text="Contact Us"
     icon="fa-solid fa-headset fa-sm"
     onClick={toggleForm}
    />
    {isOpen && (
     <div className="contact-form">
      <div className="contact-form-details">
       <div className="title">
        <p className="sub-title">Contact us</p>
        <i
         className="fa-sharp fa-solid fa-xmark fa-lg"
         onClick={toggleForm}
        ></i>
       </div>
      </div>
      <div className="form-fields">
       <form onSubmit={handleSubmit(onSubmit)}>
        <input
         type="text"
         placeholder="Your Email"
         {...register("email", {
          required: true,
          pattern: {
           value:
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/,
           message: "Pls provide the valid email address!",
          },
         })}
        />
        {errors?.email ? (
         <p className="form-error-msg">Pls provide the valid email address!</p>
        ) : null}
        <input
         type="tel"
         placeholder="Mobile number"
         {...register("phoneNumber", {
          required: true,
          pattern: {
           value: /^(?:(?:\\+|0{0,2})91(\s*[\\-]\s*)?|[0]?)?[6789]\d{9}$/,
           message: "Pls provide the valid phone number!",
          },
          maxLength: 10,
         })}
        />
        {errors?.telephone ? (
         <p className="form-error-msg">Pls provide the valid phone number!</p>
        ) : null}
        <select {...register("service")}>
         <option value="null" defaultValue disabled>
          Select
         </option>
         {ourServices.map((val) => {
          return (
           <option value={val.value} key={val.value}>
            {val.name}
           </option>
          );
         })}
        </select>

        <textarea
         id="tellUsMore"
         name="comments"
         rows="4"
         cols="50"
         placeholder="Tell us more"
         {...register("comments")}
        ></textarea>

        <button className="main-btn" type="submit">
         Get Started
        </button>
       </form>
      </div>
     </div>
    )}
   </div>
  </>
 );
};

export default withWidth()(InquiryForm);

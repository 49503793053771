import LandingPage from "./pages/LandingPage";
import { usePromiseTracker } from "react-promise-tracker";
import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

const Loading = () => {
  const { promiseInProgress } = usePromiseTracker();
  return (
    promiseInProgress && (
      <div className="preloader">
        <div className="loader">
          <div className="ytp-spinner">
            <div className="ytp-spinner-container">
              <div className="ytp-spinner-rotator">
                <div className="ytp-spinner-left">
                  <div className="ytp-spinner-circle"></div>
                </div>
                <div className="ytp-spinner-right">
                  <div className="ytp-spinner-circle"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

function App() {
  return (
    <>
      <Loading />
      <LandingPage />
    </>
  );
}

export default App;

import React from "react";

const Button = (props) => {
 return (
  <button
   type={props.type || "button"}
   id="comp-btn"
   className="btn-container"
   onClick={props.onClick}
   disabled={props.disabled}
  >
   <span className="btn-text">{props.text}</span>
   <span className="btn-icon">
    <i className={props.icon}></i>
   </span>
  </button>
 );
};

export default Button;

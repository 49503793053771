import React from "react";
import JSON from "constants/db.json";
import Slider from "react-slick";

function Advantages() {
 const settings = {
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  speed: 500,
  autoplaySpeed: 1500,
  arrows: false,
  swipe: true,
  autoplay: true,
 };

 const { advantages } = JSON;
 return (
  <div className="advantage_container">
   <div
    style={{
     display: "flex",
     justifyContent: "center",
     alignItems: "center",
    }}
   >
    <img
     src="assets/images/logo_.png"
     style={{
      height: "50px",
      marginRight: "10px",
      marginBottom: "5px",
     }}
     alt="...."
    />
    <h1 className="title-title" style={{ marginBottom: "0px" }}>
     Advantage
    </h1>
   </div>
   <div className="advantage_slider">
    <Slider {...settings}>
     {advantages.map((item, index) => {
      return (
       <div className="grid-item" key={index}>
        <div className="content">
         <img src={item.image} alt="..." />
         <p>{item.name}</p>
        </div>
       </div>
      );
     })}
    </Slider>
   </div>
   <div className="mt-30">
    <div className="grid-container">
     {advantages.map((item, index) => {
      return (
       <div className="grid-item" key={index}>
        <div className="content">
         <img src={item.image} alt="..." />
         <p>{item.name}</p>
        </div>
       </div>
      );
     })}
    </div>
   </div>
  </div>
 );
}

export default Advantages;
